import React from "react";
import { Link, graphql } from "gatsby";
import kebabCase from "lodash/kebabCase";
import { Divider, H4, Tag } from "@blueprintjs/core";

export const PostsByTag = ({ tags = { group: [] } }) => {
    return (
        <div className="wrapper">
            <div className="post-tags-wrapper">
                {tags.group.map((tag) => (
                    <Tag large minimal interactive key={tag.fieldValue}>
                        <Link to={`/blog/tag/${kebabCase(tag.fieldValue)}/`}>
                            {tag.fieldValue} ({tag.totalCount})
                        </Link>
                    </Tag>
                ))}
            </div>
        </div>
    );
};

export default PostsByTag;
