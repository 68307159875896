import React from "react";
import { BlogPostCard } from "../components/blog-post-card";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PostsByTag from "../components/posts-by-tag";
import { SEO, SeoObject } from "../components/seo";

export const Blog = ({ data }) => {
    let meta: SeoObject = {
        description: "James Collins' personal blog feed, mostly about software engineering",
        title: `Blog - jracollins.com`,
        image: `/blog/og-image.jpg`,
    };

    return (
        <Layout>
            <SEO meta={meta} />

            <div className="blog-posts-header">
                <h1>Latest Posts:</h1>
                <PostsByTag tags={data.tags} />
            </div>
            <div className="wrapper row-gap blog-posts">
                {data.posts.nodes.map(({ excerpt, frontmatter, fields, id }) => (
                    <BlogPostCard {...{ excerpt, frontmatter, fields }} key={id} />
                ))}
            </div>
        </Layout>
    );
};

export default Blog;

export const query = graphql`
    query BLOG_INDEX_QUERY($status: [String]!) {
        tags: allMdx(filter: { frontmatter: { type: { eq: "blogPost" }, status: { in: $status } } }) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }

        posts: allMdx(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { status: { in: $status }, type: { eq: "blogPost" } } }, limit: 20) {
            nodes {
                id

                excerpt(pruneLength: 250)
                frontmatter {
                    title
                    slug
                    tags
                    date(formatString: "DD MMMM YYYY")
                    relativeDate: date(fromNow: true)
                    isoDate: date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
                    excerpt
                }
                fields {
                    readingTime {
                        text
                    }
                }
            }
        }
    }
`;
